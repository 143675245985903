export default {
  sidebarWidth: '60%',
  sidebarWidthTablet: '52px',
  drawerWidthDesktop: '360px',
  drawerWidthTablet: '320px',
  drawerHeightPhone: '250px',
  filterFormWidth: '160px',
  eventsDrawerWidth: '320px',
  bottomBarHeight: 56,
  popupMapOffset: 300,
  popupMaxWidth: 288,
  popupWidthNotPhone: '100%',
  popupImageHeight: 120,
};
